<template>
  <div class="page-user">
    <span
      class="return-link d-flex align-items-center justify-content-start"
      @click.prevent="goBack"
    >
      <CIcon name="cil-arrow-left" class="mr-2" />
      Go back
    </span>

    <div class="d-flex align-items-center justify-content-between mb-4">
      <div class="title">
        <h3>User profile</h3>
        <p>Update user information details</p>
      </div>
    </div>

    <h4 class="mb-3" v-if="crud === 'edit'">
      {{ user.username }}
    </h4>

    <ValidationObserver ref="observer">
      <CForm
        slot-scope="{ validate }"
        @submit.prevent="validate().then(handleSubmit)"
      >
        <CRow v-if="crud === 'add'">
          <CCol sm="12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <label for="username">Username</label>
              <input
                class="form-control mb-4"
                type="username"
                id="username"
                name="Username"
                aria-describedby="username-feedback"
                placeholder="Username"
                v-model="user.username"
              />
              <small class="feedback text-warning" id="username-feedback">{{
                errors[0]
              }}</small>
            </ValidationProvider>
          </CCol>
        </CRow>

        <CRow>
          <CCol sm="12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <label for="email">Email</label>
              <input
                class="form-control mb-4"
                type="email"
                id="email"
                name="Email"
                aria-describedby="email-feedback"
                placeholder="Email"
                v-model="user.email"
              />
              <small class="feedback text-warning" id="email-feedback">{{
                errors[0]
              }}</small>
            </ValidationProvider>
          </CCol>
        </CRow>

        <CRow>
          <CCol sm="12">
            <label for="email">Password</label>
            <input
              class="form-control mb-4"
              type="password"
              id="password"
              name="Password"
              aria-describedby="password-feedback"
              placeholder="Password"
              v-model="password"
            />
          </CCol>
          <CCol
            sm="6"
            class="mt-4 d-flex align-items-center justify-content-start"
          >
            <label for="blocked" class="text-dark"
              ><strong>Inactive</strong></label
            >
            <CSwitch
              class="ml-4"
              color="primary"
              :checked.sync="user.blocked"
              id="blocked"
              size="lg"
              shape="pill"
            />
          </CCol>
        </CRow>

        <div
          class="options-row d-flex align-items-center justify-content-start"
        >
          <CButton
            @click="tryToDeleteUser()"
            v-if="crud === 'edit' && !isMe"
            color="outline-primary"
            class="iemq d-flex align-items-center justify-content-between"
            :disabled="loading"
          >
            Remove user <CIcon class="m-0 ml-1" name="cil-trash" />
          </CButton>
          <CButton
            type="submit"
            color="primary"
            class="ml-2 iemq"
            :disabled="loading"
          >
            Save changes
          </CButton>
        </div>
      </CForm>
    </ValidationObserver>

    <CModal :show.sync="deleteModal">
      <p>
        Delete this user permanently?
      </p>

      <template #footer>
        <div class="d-flex align-items-center justify-content-center w-100">
          <CButton
            @click="cancelDeleteConfirmation"
            color="outline-primary"
            class="iemq mr-3"
          >
            No
          </CButton>
          <CButton
            @click="acceptDeleteConfirmation"
            color="primary"
            class="iemq"
          >
            Yes, I'm sure
          </CButton>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapState, mapActions } from "vuex";
import axios from "axios";
import qs from "qs";

const apiUrl = process.env.VUE_APP_API_URL || "http://localhost:1337";

export default {
  name: "PUser",

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.cameFromUsers = from.fullPath.includes("users");
    });
  },

  data() {
    return {
      cameFromUsers: false,
      user: {},
      crud: "add",
      password: "",
      deleteModal: false,
    };
  },

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  computed: {
    ...mapState({
      me: (state) => state.auth.user,
      jwtToken: (state) => state.auth.jwt,
      loggedUser: (state) => state.auth.user,
      loading: (state) => state.app.loading,
    }),

    isProfilePage() {
      return this.$route.name === "Profile";
    },

    isMe() {
      return this.user.id === this.me.id;
    },
  },

  async created() {
    try {
      await this.startLoading();
      await this.fetchUser();
    } catch (error) {
      console.log({ error });
    } finally {
      await this.stopLoading();
    }
  },

  methods: {
    ...mapActions({
      startLoading: "app/startLoading",
      stopLoading: "app/stopLoading",
    }),

    async fetchUser() {
      if (this.isProfilePage) {
        this.user = this.me;
        this.crud = "edit";
      } else {
        const userId = this.$route.params.id;

        if (userId) {
          this.crud = "edit";

          const userResponse = await axios.get(`${apiUrl}/users/${userId}`, {
            headers: {
              Authorization: `Bearer ${this.jwtToken}`,
            },
          });

          this.user = userResponse.data;
        }
      }
    },

    tryToDeleteUser() {
      this.deleteModal = true;
    },

    cancelDeleteConfirmation() {
      this.deleteModal = false;
    },

    async acceptDeleteConfirmation() {
      this.deleteModal = false;

      try {
        await this.startLoading();
        await this.deleteUser();
        this.goBack();
      } catch (error) {
        this.$toasted.error("Something went wrong", { icon: "error" });
        console.log({ error });
      } finally {
        await this.stopLoading();
      }
    },

    async deleteUser() {
      await axios.delete(`${apiUrl}/users/${this.user.id}`, {
        headers: {
          Authorization: `Bearer ${this.jwtToken}`,
        },
      });

      this.$toasted.success("User successfully deleted", {
        icon: "check_circle",
      });
    },

    async upsertUser() {
      const baseUrl = `${apiUrl}/users/${this.user.id || ""}`;
      const user = {
        account: this.loggedUser.account.id,
        username: this.user.username,
        email: this.user.email,
        blocked: this.user.blocked || false,
        confirmed: true,
        ...(this.password ? { password: this.password } : {}),
      };

      await axios[this.crud === "add" ? "post" : "put"](
        baseUrl,
        qs.stringify(user),
        {
          headers: {
            Authorization: `Bearer ${this.jwtToken}`,
          },
        }
      );

      this.$toasted.success("User successfully saved", {
        icon: "check_circle",
      });

      if (this.crud === "add") {
        this.goBack();
      }
    },

    async handleSubmit() {
      try {
        await this.startLoading();
        await this.upsertUser();
      } catch (error) {
        this.$toasted.error("Something went wrong", { icon: "error" });
        console.log({ error });
      } finally {
        await this.stopLoading();
      }
    },

    goBack() {
      this.cameFromUsers
        ? this.$router.go(-1)
        : this.$router.push({ path: "/users" });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-user {
  padding: 32px 24px;

  .iemq-custom-switch {
    width: 70px;
    height: 35px;

    ::v-deep {
      .c-switch-slider::before {
        top: 4px;
        left: 4px;
        height: 25px;
      }

      &:checked ~ .c-switch-slider::before {
        transform: translateX(35px);
      }
    }
  }

  .feedback {
    position: absolute;
    bottom: 0;
  }

  .return-link {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    text-decoration: none;
    margin-bottom: 30px;
    cursor: pointer;
  }

  .options-row {
    margin-top: 100px;
    max-width: 560px;
    margin-bottom: 40px;
  }

  input {
    max-width: 560px;
  }
}
</style>
